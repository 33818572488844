@font-face {
  font-family: "Inter";
  src: url("/public/assets/fonts/Inter-Black.ttf");
  font-weight: 900;
}

@font-face {
  font-family: "Inter";
  src: url("/public/assets/fonts/Inter-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "Inter";
  src: url("/public/assets/fonts/Inter-ExtraBold.ttf");
  font-weight: 800;
}
@font-face {
  font-family: "Inter";
  src: url("/public/assets/fonts/Inter-ExtraLight.ttf");
  font-weight: 200;
}
@font-face {
  font-family: "Inter";
  src: url("/public/assets/fonts/Inter-Light.ttf");
  font-weight: 300;
}
@font-face {
  font-family: "Inter";
  src: url("/public/assets/fonts/Inter-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: "Inter";
  src: url("/public/assets/fonts/Inter-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Inter";
  src: url("/public/assets/fonts/Inter-SemiBold.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "Inter";
  src: url("/public/assets/fonts/Inter-Thin.ttf");
  font-weight: 100;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/*TODO:  ========= default ========= */

.products-sorting.w-full.bg-white.md\:h-\[70px\].flex.md\:flex-row.flex-col.md\:space-y-0.space-y-5.md\:justify-between.md\:items-center.p-\[30px\].mb-\[40px\] {
  display: none;
}
*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html {
  font-family: "Inter", sans-serif;
  background-color: #f8f8f8;
}
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.font-100 {
  font-weight: 100;
}
.font-200 {
  font-weight: 200;
}
.font-300 {
  font-weight: 300;
}
.font-400 {
  font-weight: 400;
}
.font-500 {
  font-weight: 500;
}
.font-600 {
  font-weight: 600;
}
.font-700 {
  font-weight: 700;
}
.font-800 {
  font-weight: 800;
}
.font-900 {
  font-weight: 900;
}
/* .container-x {
  @apply md:px-6 px-2 w-full;
  max-width: 1216px !important;
} */
.animate-right-dir {
  animation: rightDir 1s infinite;
}
@keyframes rightDir {
  0%,
  100% {
    transform: translatex(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

.yellow-btn {
  @apply w-full text-[13px] text-qblacktext font-semibold bg-qyellow flex justify-center items-center h-full;
  line-height: 0;
}
.blue-btn {
  @apply w-full text-[13px] text-white font-semibold bg-qh3-blue flex justify-center items-center h-full;
  line-height: 0;
}

.gray-btn {
  @apply flex justify-center items-center text-sm font-600;
  background: #f0f1f3;
}

.overflow-style-none::-webkit-scrollbar {
  display: none;
}

.px-\[30px\] {
  padding-left: 14px !important;
  padding-right: 14px !important;
}
/* Hide scrollbar for IE, Edge and Firefox */
.overflow-style-none {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/*TODO:  ========= home one ========= */
.home-one .search-btn {
  @apply bg-qyellow text-qblack;
}
.home-one .cart-wrappwer {
  @apply border-qyellow;
}
/*TODO:  ========= home one end ========= */
/*TODO:  ========= home two ========= */
.home-two .search-btn {
  @apply bg-qh2-green text-white;
}
.home-four .search-btn {
  @apply bg-qh4-pink text-qblack;
}
.home-four .cart-wrappwer {
  @apply border-qh4-pink;
}
.home-five .search-btn {
  @apply bg-qh5-bwhite text-qblack;
}
.home-five .cart-wrappwer {
  @apply border-qh5-bwhite;
}
.home-two
  :is(.cart-wrappwer, .product-card-style-one-two, .product-row-card-style-one-two)
  .yellow-btn {
  @apply bg-qh2-green text-white;
}
.home-four .yellow-btn {
  @apply bg-qh5-bwhite text-qblack;
}
/*TODO:  ========= home two end ========= */
.search-input {
  @apply w-full h-full focus:outline-none focus:ring-0 pl-5 text-xs font-500 placeholder:text-qgraytwo;
}
.black-btn {
  @apply bg-qblack text-white;
}
.breadcrumb-wrapper span:last-child .sperator {
  display: none;
}

/*TODO:  =========header ========= */
.category-dropdown {
  transition: all 0.5s ease-in-out;
}
.category .category-dropdown .categories-list .category-item {
  border-top: 1px solid #f8f8f8;
}
/* width */
.quomodo-shop-middle-bar .product-items::-webkit-scrollbar {
  width: 2px;
}

/* Track */
.quomodo-shop-middle-bar .product-items::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.quomodo-shop-middle-bar .product-items::-webkit-scrollbar-thumb {
  background: #8e8e8e;
}

/* Handle on hover */
.quomodo-shop-middle-bar .product-items::-webkit-scrollbar-thumb:hover {
  background: #8e8e8e;
}

.quomodo-shop-middle-bar .product-items ul li:first-child {
  margin-top: 14px;
}

/* ======mega menu====== */
.nav-widget-wrapper .nav ul.nav-wrapper > li {
  padding: 20px 0;
}
.nav-widget-wrapper .nav ul li .sub-menu {
  transition: all 0.5s ease-in-out;
  opacity: 0;
  visibility: hidden;
  pointer-events: auto;
}
.nav-widget-wrapper .nav ul li:hover .sub-menu {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}
/*TODO: ===============home page============ */

.header-section-wrapper
  .topbar-dropdowns
  :is(.country-select, .currency-select, .language-select)
  .my-select-box
  .my-select-box-btn
  > span {
  @apply text-xs font-500 text-qblack;
  text-transform: capitalize;
}

.header-section-wrapper
  .topbar-dropdowns
  .country-select
  .my-select-box
  .my-select-box-section {
  width: 150px;
  left: auto;
  right: 0;
}
.header-section-wrapper
  .topbar-dropdowns
  :is(.currency-select, .language-select)
  .my-select-box
  .my-select-box-section {
  width: 120px;
  left: auto;
  right: 0;
}
.header-section-wrapper
  .topbar-dropdowns
  .my-select-box
  .my-select-box-section {
  width: 120px;
  left: auto;
  right: 0;
}
.header-section-wrapper
  .topbar-dropdowns
  :is(.country-select, .currency-select, .language-select)
  .my-select-box
  .my-select-box-section
  li {
  font-size: 12px;
  text-transform: capitalize;
}

/*TODO: ===============all product page============ */

.products-page-wrapper
  .filter-widget
  .filter-subject-item
  .filter-items
  ul
  li.item:last-child {
  margin-bottom: 0;
}
/* range slider */
.input-range__track {
  background: #d4d4d4;
}
.input-range__track.input-range__track--active {
  background: #ffbb38;
}
.input-range__label.input-range__label--max,
.input-range__label.input-range__label--min,
.input-range__label.input-range__label--value {
  display: none;
}
.input-range__slider {
  appearance: none;
  background: #3f51b5;
  border: 1px solid #3f51b5;
}

.input-range__slider {
  background: #ffffff;
  border: 1px solid #ffffff;
  width: 14px;
  height: 14px;
  box-shadow: 0px 3px 10px 0px rgba(204, 204, 204, 1);
}

/*TODO: =============== product compaire============ */

.products-compaire-wrapper
  .table-wrapper
  .table-row-wrapper
  .product:last-child {
  border-right: none;
}
.products-compaire-wrapper .table-wrapper .table-row-wrapper:nth-child(2) td {
  padding-top: 26px;
}
.products-compaire-wrapper .table-wrapper .table-row-wrapper:last-child > td {
  padding-bottom: 45px;
}

/*TODO: =============== about compaire============ */

.about-page-wrapper .feedback-slider-wrapper .slick-slide > div {
  margin: 0 15px;
}
.react-multiple-carousel__arrow{
  background: none !important;
}
/*TODO: =============== profile ============ */
.items-wrapper-bank-payment .items li:last-child {
  border-bottom: none;
}
.hero-slider-wrapper .slick-dots {
  bottom: 30px;
  text-align: center;
}
.hero-slider-wrapper .slick-dots li button:before {
  content: "";
  background: #000000;
  width: 22px;
  height: 7px;
  border-radius: 4px;
}

.home-goto-cart {
  height: 30px;
  width: 110px;
}

.bg-qh3-blue {
  background-color: #526fc7 !important;
  /* z-index: -1; */
}

.bg-qyellow {
  background-color: #526fc7 !important;
  color: #fff !important;
}

.bg-qh3-qyellow {
  background-color: #526fc7 !important;
  color: #fff !important;
}
.errorMsg {
  color: #e90f0f;
  font-size: 12px;
  /* position: absolute; */
}

.input-area-cart {
  padding: 20px 20px 20px 20px;
}
/* TODO: ===============RESPONSIVE */

.black-btn {
  background-color: #526fc7 !important;
  color: #fff !important;
}

.marquee {
  white-space: nowrap;
  overflow: hidden;
  animation: marquee 40s linear infinite;
  z-index: -1;
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.input-item.mb-5.input-flex {
  width: 45%;
}
.input-body.input-flex {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.input-height {
  height: 48px;
}

.react-dropdown-select.undefined.css-wmy1p7-ReactDropdownSelect.e1gzf2xs0 {
  border-color: rgb(239 239 239 / var(--tw-border-opacity));
  background-color: #fff;
  padding: 14px;
}
.css-1c8t16q-InputComponent {
  font-size: 14px !important;
}
.flex.justify-center .font-semibold {
  font-weight: 600;
  margin-top: 22px;
}

.slick-prev,
.slick-next {
  z-index: 1 !important;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: unset;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  opacity: unset;
  color: #526fc7;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-next {
  right: 10px;
}
.slick-prev {
  left: 10px;
}
.our-box-client {
  margin: 23px 39px;
  background-color: #fff;
  padding: 20px 30px 20px 30px;
}
.client-head h1 {
  font-size: 30px;
  font-weight: 600;
  padding-top: 20px;
}
.more-client a {
  font-size: 16px;
  font-weight: 600;
}
.Our-client {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
}

.client-head::before {
  content: " ";
  display: block;
  background: var(--primary-color);
  width: 108px;
  position: absolute;
  height: 4px;
  margin-top: 63px;
  margin-bottom: -2px;
}
.client-logo {
  display: flex;
  align-items: center;
  justify-content: start;
  margin: 23px 9px;
  width: 100%;
  flex-wrap: wrap;
}
.our-client-logos {
  width: 14%;
}
.our-client-logos img {
  width: 100px;
  text-align: center;
  margin-bottom: 15px;
  margin-top: 15px;
}

@media (max-width: 640px) {
  .track-slide::after {
    top: 113px !important;
  }
  .client-logo {
    flex-direction: row;
    align-items: start;
  }
  .client-head::before {
    content: " ";
    display: block;
    background: var(--primary-color);
    width: 108px;
    position: absolute;
    height: 4px;
    margin-top: 48px;
    margin-bottom: -2px;
  }
  .client-head h1 {
    font-size: 20px;
  }
  .our-client-logos {
    width: 41%;
    margin-bottom: 20px;
    margin-right: 24px;
  }
  .our-box-client {
    margin: 0px;
    padding: 0px 30px 1px 30px;
  }
  .our-client-logos img {
    width: 60%;
    margin: 0 auto;
  }
  .flex-row-section {
    display: block !important;
  }
  .baaner-section {
    width: 100% !important;
  }

  .img-logo-icon img {
    width: 25% !important;
  }
  .img-logo-info img {
    width: 100%;
  }
  .img-logo-info img {
    width: 49%;
  }
  .nav-widget-wrapper.w-full.h-\[60px\].relative.z-30.bg-qh3-blue {
    display: none !important;
  }
  .products-sorting.w-full.bg-white.md\:h-\[70px\].flex.md\:flex-row.flex-col.md\:space-y-0.space-y-5.md\:justify-between.md\:items-center.p-\[30px\].mb-\[40px\] {
    display: inline-block;
  }
  .container-x.mx-auto {
    padding: 0px 30px;
  }
  .input-item.mb-5.input-flex {
    width: 100% !important;
  }
  .input-body.input-flex {
    flex-direction: column;
  }
  .input-com.w-full.h-full {
    margin-bottom: 16px !important;
    padding-bottom: 16px;
    display: inline-block;
  }
  .react-dropdown-select.undefined.css-wmy1p7-ReactDropdownSelect.e1gzf2xs0 {
    padding: 4px !important;
  }
}

@media (max-width: 768px) {
  .category-products .products-section .item:nth-child(3),
  .best-sallers-section .section-content .item:nth-child(3),
  .best-sallers-section .section-content .item:nth-child(4),
  .single-product-wrapper .related-product .item:nth-child(3) {
    display: none;
  }
}

@media (max-width: 1024px) {
  .best-sallers-section .section-content .item:nth-child(6),
  .single-product-wrapper .saller-product .item:nth-child(8),
  .single-product-wrapper .saller-product .item:nth-child(7),
  .single-product-wrapper .related-product .item:nth-child(4) {
    display: none;
  }
}

@media (max-width: 1280px) {
}

@media (max-width: 1536px) {
}

/* TODO: ===============RESPONSIVE END */

.img-logo-icon img {
  width: 5%;
}

.img-logo-info {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0px;
}

.flex-row-section {
  display: flex;
}
.baaner-section {
  width: 50%;
  border: 2px solid;
  border-color: white;
}
/* 
.main-wrapper.main-text-offer.h-full.xl\:h-\[313px\].h-\[85px\] {
  overflow: hidden;
} */

.offer-body {
  display: flex;
  flex-direction: column;
}
.offer-section img {
  height: 157px;
}
.offer-section img {
  height: 152px;
  width: 414px;
}

.offer-section {
  margin-bottom: 10px;
  margin-left: 12px;
}

.track-slide {
  background-color: #526fc7;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  padding: 5px 4px;
  width: 130px;
  z-index: 9;
  font-size: 11px;
  height: 42px;
  line-height: 15px;
}

.track-slide::after {
  content: "";
  position: absolute;
  left: 123px;
  top: 159px;
  width: 0;
  height: 0;
  border-top: 13px solid transparent;
  border-left: 26px solid #526fc7;
  border-bottom: 13px solid transparent;
}

/* .track-slide::after {
  content: "";
  position: absolute;
  right: 100%;
  top: 26px;
  width: 0;
  height: 0;
  border-top: 13px solid transparent;
  border-right: 26px solid red;
  border-bottom: 13px solid transparent;
} */
.topbar-nav {
  display: flex;
  /* margin: 10px 0px; */
  align-items: center;
}
.w-full.bg-white.h-20.border-b.border-qgray-border {
  margin: -15px 0px;
}

span.track-info {
  margin-top: -3px !important;
  padding-top: 12px;
  display: block;
}

span.amount-name {
  padding-right: 18px;
  /*background: var(--ticker-prd-name) 0% 0% no-repeat padding-box!important;
  */
  background-color: #526fc773;
  font-weight: bold;
  line-height: 11px !important;
  padding: 12px 12px;
  margin-top: 4px;

  font-size: 10px;
  border-radius: 20px;
  margin-right: 12px;

  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}
span.amount-text {
  font-size: 10px;
  margin-top: 4px;
}

span.amount-price {
  font-size: 10px;
  font-weight: bold;
  margin-top: 4px;
  margin-right: 24px;
}

span.text-xs.leading-6.text-qblack.font-500 {
  display: flex;
  justify-content: center;
  align-items: center;
}

span.text-sm.customer-info.font-600.spa {
  color: #fff;
}
span.text-sm.btn-login.font-600 {
  background-color: #fff;
  padding: 5px 8px;
  border-radius: 7px;
  color: black;
  margin-left: 15px;
}

.offer-section-one img {
  height: 313px;
}

.login-mobile img {
  width: 29px;
}

span.text-xs.leading-6.text-qblack.font-500 {
  border-right: 1px solid;
}
.rounded.bg-white {
  padding: 20px;
  text-align: center;
}

/* Add these CSS styles to your stylesheet */
.team-hover {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: transform 0.2s;
}

.team-hover:hover {
  transform: scale(1.05);
}

.linked-in-link {
  position: absolute;
  left: 50%;
  bottom: 30px;
  transform: translateX(-50%);
}
.blog-card-wrapper .linkedin-link {
  display: none;
}

.blog-card-wrapper:hover .linkedin-link {
  display: block;
}
.text-blue-500 {
  color: #526fc7 !important;
}

.contact-radio {
  /* Your custom styles here */
  width: 20px; /* Increase the size */
  height: 20px;
  background-color: black; /* Change the color */
}
.contact-radio:checked::before {
  content: "";
  width: 10px; /* Adjust the size of the inner circle */
  height: 10px;
  background-color: black; /* Change the inner circle color when selected */
  border-radius: 50%;
  display: block;
  margin: 5px; /* Adjust the margin to center it */
}

li.react-multi-carousel-item.react-multi-carousel-item--active {
  /* text-align: center; */
  text-align: -webkit-center;
}
.news-info {
  padding: 18px 0pc;
}
.react-multiple-carousel__arrow--right::before {
  content: "\e825";
  left: 47px;
  color: #fff;
  background-color: #526fc7;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  margin: 0px 13px;
}
/* .react-multiple-carousel__arrow--left::before {
  content: "\e824";
  color: black;
  right: 55px;
  
} */
.react-multiple-carousel__arrow {
  border: 0;
  border-radius: 35px;
  cursor: pointer;
  min-height: 40px;
  min-width: 40px;
  opacity: 1;
  outline: 0;
  position: absolute;
  transition: all 0.5s;
  z-index: 1000;
}

.react-multiple-carousel__arrow--left::before {
  content: "\e824";
  color: #fff;
  right: 47px;
  background-color: #526fc7;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  margin: 0px 13px;
}

.new-view .react-multiple-carousel__arrow--right::before {
  content: "\e825";
  color: black;
  left: 52px;
  background-color: #526fc7;
  top: -8px;
  font-size: 14px;
  width: 32px;
  height: 32px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.new-view .react-multiple-carousel__arrow--left::before {
  content: "\e824";
  color: black;
  right: 44px;
  background-color: #526fc7;
  top: -8px;
  font-size: 14px;
  width: 32px;
  height: 32px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.react-multiple-carousel__arrow:hover {
  background-color: transparent !important;
  color: #fff !important;
}

.market-place h1 {
  font-size: 30px;
  font-weight: 600;
}

.market-place {
  margin-top: 50px;
}

.logo-btn {
  margin: 20px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.body-news {
  background-color: #fff;
  padding: 30px;
}

.text-info {
  font-size: 10px;
  position: relative;
  color: #fff;
  top: -43px;
  padding: 0px 13px;
  width: 124px;
}

.latest-news h1 {
  font-size: 23px;
  font-weight: 600;
}
.latest-news {
  margin-top: 40px;
}
.marcket-btn.d-flex button {
  margin: 6px 12px;
}
.d-flex {
  display: flex;
  /* justify-content: center; */
}
.news-img {
  margin-right: 15px;
}

.news-img img {
  width: 206px;
  height: 135px;
}
/* .new-left {
  margin: 25px 0px;
} */
.price-info.mr-2 {
  margin-right: 4px;
}
.new-left {
  padding: 18px;
  width: 76%;
  margin: 0px 12px 0px 0px;

  background-color: #fff;
}
span.mt {
  font-size: 11px;
}

.news-text h3 {
  font-weight: bold;
  margin-bottom: 9px;
  font-size: 18px;
}

.news-text span {
  display: flex;
  align-items: center;
}
span.m-l-15 {
  margin-left: 8px;
}

p.d-flex.m-r-15 span {
  margin-right: 15px;
}

span.m-l-15.btn-market {
  background-color: #526fc76b;
  padding: 2px 7px;
  border-radius: 12px;
}
a.share-btn-whatup {
  display: flex;
  align-items: center;
}
.container-x.mx-auto.h-full.latest-news {
  padding: 30px 0px;
}
.latest-marcket-news {
  display: flex;
}

.d-flex.d-info {
  border: 1px solid #f0e3e3;
  padding: 18px;
  margin: 20px 0px;
}
.news-right {
  padding: 12px 12px;
  width: 24%;
  background-color: #fff;
}
.time-ago {
  display: flex;
  align-items: center;
  margin-left: 12px;
}
.d-flex.d-info.flex-wrap {
  padding: 18px 11px;
}
.mr-15 {
  margin-right: 15px;
}
.location-info {
  display: flex;
  width: 50%;
  margin: 5px 0px;
  align-items: center;
}
.d-flex.d-info.materials-info {
  padding: 0px 12px;
}

.pagination {
  display: inline-block;
  float: right;
}

.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  border: 1px solid #ddd;
}

.pagination a.active {
  background-color: #b6c3e8;
  color: white;
  border: 1px solid #b6c3e8;
}

.pagination a:hover:not(.active) {
  background-color: #ddd;
}

.pagination a:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.pagination a:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.p-dialog {
  max-height: fit-content;
}
.p-dialog-content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;
  flex-direction: column;
}
.modal-body-info {
  padding: 0px 26px;
}

.product-card-img.w-full.h-\[200px\] {
  width: 181px;
  height: 181px;
}
.product-card-details
  p.title.mb-2.text-\[15px\].font-600.text-qblack.leading-\[24px\].line-clamp-2.hover\:text-blue-600 {
  height: 57px;
}

.section-style-one.new-products.mb-\[60px\] {
  margin: 23px 39px;
  background-color: #fff;
  margin: 0x 42px !important;
}
.section-title.flex.justify-between.items-center.mb-5 {
  padding-top: 35px;
}
.product-card-one:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.container-x.mx-auto {
  margin-left: 30px;
  margin-right: 30px;
}

.product-card-details
  p.title.mb-2.text-\[15px\].font-600.text-qblack.leading-\[24px\].line-clamp-2.hover\:text-blue-600 {
  height: 73px;
}
.section-title.flex.justify-between.items-center.mb-5 {
  border-bottom: 1px solid red;
  padding-bottom: 12px;
}
.section-title.flex.justify-between.items-center.mb-5 {
  border-bottom: 1px solid #dfdfdf;
  padding-bottom: 12px;
}
.section-title.flex.justify-between.items-center.mb-5:before {
  content: " ";
  display: block;
  background: var(--primary-color);
  width: 108px;
  position: absolute;
  height: 4px;
  margin-top: 57px;
  margin-bottom: -2px;
}
.group:hover .group-hover\:top-\[50px\] {
  top: 60px !important;
}

span.amount-price img {
  width: 12px;
  margin-left: 12px;
}
span.amount-price {
  display: flex;
  align-items: center;
}
.pdp-need-help {
  width: 320px;
  height: 83px;
  border: 1px solid var(--divider-color);
}
.ml-15 {
  margin-left: 15px;
}
.need-to-help {
  border: 1px solid #dfdfdf;
  padding: 12px;
  display: inline-block;
  margin-top: 15px;
}

.row.d-flex.justify-content-between {
  /* justify-content: normal; */
  justify-content: space-between;
}
.col-6.w-50 {
  width: 50%;
}

.mr-15 {
  margin-right: 15px;
}
.pr-30 {
  padding-right: 30px;
}
.col-md-3.text-center.dilivery-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.col-md-3.text-center.dilivery-box {
  font-size: 12px;
  color: #006cb5;
}
.dilivery-time {
  margin-bottom: 30px;
}
.need-to-help {
  margin-bottom: 30px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-30 p {
  text-align: justify;
}

.col-md-3.text-center.dilivery-box img {
  width: 35px;
}
.content {
  padding: 30px 0px;
}
.w-50 {
  width: 50%;
}
.mb-15 {
  margin-bottom: 15px;
}

.suggestions-menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc; /* Add a border */
  border-radius: 4px; /* Add rounded corners */
  max-height: 200px; /* Limit the height to create a scrolling effect */
  overflow-y: auto; /* Enable vertical scrolling */
  background-color: #ffffff;
  position: absolute;
  z-index: 9999;
}
.flex-1.flex-three {
  border: 1px solid #dfdfdf;
  padding: 15px;
}

.suggestion-item {
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Add transition effect */
}

.suggestion-item:hover {
  background-color: #f0f0f0; /* Change background color on hover */
}

.title {
  font-weight: bold;
}

.price {
  margin-left: 10px; /* Add space between title and price */
}

.flex-1.flex-two {
  margin-right: 12%;
}
.mobile-search {
  display: none;
}

@media only screen and (max-width: 500px) {
  .mobile-search {
    display: block;
  }
  .mobile-carousel-two {
    padding: 10px;
}
.leading-\[24px\]{
  font-size: 13px;
}
.pb-\[30px\] {
  padding-bottom: 0px;
}
.px-\[30px\] {
  padding-left: 0px;
  padding-right: 0px;
}
.text-\[18px\]{
  font-size: 14px !important;
}
  .search-input {
    background: transparent !important;
  }
  .react-multiple-carousel__arrow{
    background: none !important;
  }
  .mobile-search {
    padding: 6px 20px;
    border: 2px solid #526fc7;
    margin: 0px 20px;
    border-radius: 30px;
  }
  .h-\[85px\] {
    height: 160px;
  }
  .lg\:w-1\/4.lg\:flex.lg\:flex-col.items-center.w-full.mb-10.lg\:mb-0 {
    width: 50%;
  }
  .lg\:flex.justify-between.mb-\[50px\] {
    display: flex;
    flex-wrap: wrap;
  }
  .lg\:w-1\/4.lg\:flex.lg\:flex-col.items-center.w-full.mb-10.lg\:mb-0.w-100 {
    width: 100%;
  }

  .w-50 {
    width: 100%;
  }
  .row-flex-mobile {
    flex-direction: column;
  }

  .offer-section-one {
    display: none !important;
  }
  .offer-body {
    display: none !important;
  }
  .section-style-one.new-products.mb-\[60px\] {
    margin: 0px;
  }
  .container-x.mx-auto {
    margin-left: 0px;
    margin-right: 0px;
  }
  .react-multiple-carousel__arrow--right::before {
    left: 21px;

    width: 37px;
    height: 37px;
  }
  .react-multiple-carousel__arrow--left::before {
    right: 11px;
    width: 37px;
    height: 37px;
  }
  .h-\[600px\] {
    height: auto;
  }
}

.w-full.bg-white.h-20.border-b.border-qgray-border.trans {
  background-color: transparent;
}

span.flex.items-center.text-sm.font-600.cursor-pointer.text-white {
  z-index: 9999999 !important;
}
a {
  color: inherit;
  text-decoration: inherit;
  z-index: 99999999999 !important;
}
.details-page-show {
  padding-right: 30px;
}
.footer-logo {
  filter: brightness(0) invert(1);
}

.footer-bg {
  background: #526fc7;
}
.social-icon a {
  color: #fff;
}

.whatsapp-logo {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  background-color: rgb(54, 176, 54);
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.whatsapp-logo img {
  height: 30px;
  margin-bottom: 5px;
}

.whatsapp-logo p {
  margin: 0;
  font-size: 12px;
  font-weight: bold;
}

.whatsapp-logo hr {
  border: none;
  border-top: 1px solid white;
  width: 100%;
  margin: 5px 0;
}

